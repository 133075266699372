import { message } from 'antd/lib';
import { isNilOrEmpty, isNotEmpty, isNotNil, isNotNilOrEmpty, mapIndexed, notEqual } from 'ramda-adjunct';
import React, { useEffect, useRef, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useHistory } from 'react-router-dom';
import { all, always, andThen, any, anyPass, assoc, assocPath, compose, dissoc, equals, filter, find, flatten, has, head, ifElse, isEmpty, isNil, length, lensPath, lensProp, map, none, over, path, pathOr, prop, propEq, propOr, propSatisfies, reduce, uniq, when } from 'ramda';
import { uploadFiles } from '../lib/uploadFiles';
import { getElementByVersion, getFlexigetElements } from '../pages/docs/components/helpers/flexigets';
import { errorMessage, successMessage } from '../utils/messageMutation';
import { BASE_FRAME_STYLE, SCOPE_OFFER } from '../_CONST';
import CreateWidgetMutation from '../_graphql/mutations/documents/CreateWidgetMutation';
import DeleteWidgetMutation from '../_graphql/mutations/documents/DeleteWidgetMutation';
import UpdateWidgetMutation from '../_graphql/mutations/documents/UpdateWidgetMutation';
import UpdateCacheMutation from '../_graphql/mutations/UpdateCacheMutation';
import useRules from '../pages/docs/components/rules/useRules';
import { getVariablesOnWidgetHtml } from '../pages/helpers/getVariablesOnWidgetHtml';

const NB_COLS_GRID = 12;
const HEIGHT_ROWS_GRID = 5;

const useWidget = ({
  widget = {},
  scope,
  offerId,
  onOfferRedirect,
  flexigets,
  setWidgetCreated,
  variablesForRules
}) => {
  const widgetExist = isNotNil(prop('id', widget));
  const [widgetId, setWidgetId] = useState(null);
  const [elements, setElements] = useState(getFlexigetElements(flexigets));
  const [isEdit, setIsEdit] = useState(false);
  const [isOpenCreateGlobalVariableModal, setIsOpenCreateGlobalVariableModal] = useState(false);
  const [state, setState] = useState({
    values: setDefaultValues(widget, {
      scope,
      offerId
    }),
    loading: false,
    deleteLoading: false,
    isEditing: false
  });

  const widgetValues = setDefaultValues(widget, {
    scope,
    offerId
  });
  const { getConditionsAreValid } = useRules({});
  const prevContents = useRef({});
  const previousVariables = useRef([]);
  const history = useHistory();

  useEffect(() => {
    const hasChanged = notEqual(widgetValues, prop('values', state));
    if (widgetExist && hasChanged) {
      setState(assoc('isEditing', true));
    }

    const contents = path(['values', 'contents'], state);
    if (notEqual(prevContents.current, contents)) {
      map((element) => {
        const Component = getElementByVersion(prop('type', element), propOr('1.0', 'version', element)).Component;
        setElements(assoc(prop('componentName', Component), Component, elements));
      })(contents);
      prevContents.current = contents;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prop('values', state)]);

  useEffect(() => {
    if (widgetExist) {
      setState(assoc('values', widgetValues));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widget, scope, offerId, widgetExist]);

  useEffect(() => {
    const signatureElements = filter(propEq('type', 'signature'))(path(['values', 'contents'], state));
    if (length(signatureElements) > 0) {
      mapIndexed((e, id) => {
        setState((prevState) =>
          assocPath(['values', 'contents', id, 'type'], 'text', prevState));
      })(signatureElements);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path(['values', 'contents'], state)]);

  const variablesOnElements = getVariablesOnWidgetHtml(path(['values', 'contents'], state));

  const redirect = () => history.push(onOfferRedirect());

  const variablesUsedOnRules = compose(
    uniq,
    map(prop('variable')),
    flatten,
    map(path(['rule', 'conditions'])),
    filter((element) => element.rule && isNotNilOrEmpty(path(['rule', 'conditions'], element)))
  )(path(['values', 'contents'], state));
  const hasVariableOnRulesNotInVariables = any((variable) => none((v) => equals(prop('key', v), variable), variablesForRules))(variablesUsedOnRules);

  const onSave = async (reFetch, saveAndRedirect) => {
    setState(assoc('loading', true));

    const removeUnusedVariablesOnRule = (rule) => {
      const conditions = propOr([], 'conditions', rule);
      const validConditions = filter(
        (condition) => any((v) => equals(prop('key', v), prop('variable', condition)), variablesForRules),
        conditions
      );
      if (notEqual(conditions, validConditions)) {
        return assoc('conditions', validConditions, rule);
      }
      return rule;
    };

    const cleanValues = (state) => compose(
      over(
        lensProp('contents'),
        map(over(
          lensProp('rule'),
          (rule) => {
            if (hasVariableOnRulesNotInVariables) rule = removeUnusedVariablesOnRule(rule);
            return isNotNilOrEmpty(propOr([], 'conditions', rule)) ? rule : null;
          }
        ))
      ),
      propOr({}, 'values')
    )(state);

    const processImages = async (values) => {
      const hasImage = compose(
        any(propEq('type', 'image')),
        prop('contents')
      )(values);

      if (hasImage) {
        const contents = await saveImages(values);
        if (any(isNil)(contents)) {
          setState(assoc('loading', false));
          return null;
        }
        return { ...values, contents };
      }
      return values;
    };

    const updateHtmlContent = (values) => {
      when(
        (elementsFlexiget) => equals(length(path(['contents'], values)), length(elementsFlexiget)),
        map((element, key) => {
          if (equals('childrens', prop('type', element))) element.type = 'children';

          const ElementFlexiget = getElementByVersion(prop('type', element), propOr('1.0', 'version', element)).Component;
          const html = path(['element', 0, 'html'], element);
          const elementGetHtml = isNotNilOrEmpty(html);

          const content = elementGetHtml ? html : renderToStaticMarkup(<ElementFlexiget value={prop('element', element)} key={key} />);
          element.htmlContent = content;

          const elementContentSlate = compose(
            map(
              compose(
                dissoc('children'),
                when(
                  (c) => anyPass([equals('paragraph'), equals('signature'), equals('checkbox')])(prop('type', c)),
                  assoc('type', 'text')
                )
              )
            ),
            filter(has('children'))
          )(prop('element', element));
          if (isNotEmpty(elementContentSlate)) element.element = elementContentSlate;
        })
      )(prop('contents', values));

      return compose(
        over(
          lensProp('contents'),
          JSON.stringify
        )
      )(values);
    };

    let values = await compose(
      andThen(updateHtmlContent),
      async (values) => processImages(values),
      cleanValues
    )(state);

    const handleWidgetSaveCallback = successMessage => (ok, error, widgetId) => {
      setWidgetId(widgetId);
      setState(assoc('loading', false));

      if (ok && !error) {
        message.success(successMessage);
        const newName = prop('name', values);
        const nameHasChanged = notEqual(prop('name', widget), newName);
        const shouldUpdateCache = equals(SCOPE_OFFER, scope) && (nameHasChanged || !(widgetExist || isEdit));

        if (shouldUpdateCache) {
          UpdateCacheMutation({ date: new Date(), key: `widgets-${offerId}` }, () => {});
        }
        if (setWidgetCreated) {
          setWidgetCreated({
            ...values, id: widgetId, dates: { creation: new Date() }
          });
        }
        if (reFetch) {
          history.push(`/offre/${offerId}/widgets/${widgetId}/editer`);
        }
        if (saveAndRedirect) redirect();
      } else {
        errorMessage();
      }
    };

    if (widgetExist || isEdit) {
      const setId = widgetId || prop('id', values);
      UpdateWidgetMutation({ widget: { ...values, id: setId } }, handleWidgetSaveCallback('Le widget a bien été mis à jour'));
      setIsEdit(true);
    } else {
      values = dissoc('id', values);
      CreateWidgetMutation({ widget: values }, handleWidgetSaveCallback('Le widget a bien été créé.'));
    }

    setState(assoc('isEditing', false));
    return true;
  };

  const checkRuleAndSave = (refetch, saveAndRedirect) => async () => {
    const allAreValid = all(compose(
      ifElse(isNil, always(true), getConditionsAreValid),
      pathOr(null, ['rule', 'conditions'])
    ))(path(['values', 'contents'], state));

    if (allAreValid) {
      return await onSave(refetch, saveAndRedirect);
    } else {
      message.error('Veuillez vérifier la règle de chaque élément.');
    }
  };

  const onDelete = () => {
    DeleteWidgetMutation({ widgetId: prop('id', widget) }, (ok, error) => {
      if (ok && !error) {
        successMessage('widget', 'supprimé');
        if (equals(SCOPE_OFFER, scope)) {
          UpdateCacheMutation({ date: new Date(), key: `widgets-${offerId}` }, () => {});
        }
        redirect();
      } else {
        errorMessage();
      }
    });
  };

  const isValid = propsAreDefined(['name', 'scope', 'contents'])(prop('values', state));

  const updateRule = (index) => (fn) =>
    setState(over(
      lensPath(['values', 'contents', index, 'rule']),
      fn
    ));

  const removeRule = (index) => () =>
    setState(over(
      lensPath(['values', 'contents', index, 'rule']),
      always(null)
    ));

  const selectVariable = (elementIndex, conditionIndex, variable) =>
    setState(assocPath(['values', 'contents', elementIndex, 'rule', 'conditions', conditionIndex, 'variable'], variable));

  return {
    ...state,
    setState,
    onSave: checkRuleAndSave,
    redirect,
    isValid,
    isEdit,
    onDelete,
    variablesOnElements,
    setIsEdit,
    widgetExist,
    isUsed: propOr(false, 'isUsed', widget),
    isUsedBy: propOr([], 'isUsedBy', widget),
    elements,
    getConditionsAreValid,
    updateRule,
    removeRule,
    isOpenCreateGlobalVariableModal,
    setIsOpenCreateGlobalVariableModal,
    selectVariable,
    hasVariableOnRulesNotInVariables
  };
};

const setDefaultValues = (widget, {
  scope,
  offerId
}) => ({
  id: propOr(undefined, 'id', widget),
  name: propOr('', 'name', widget),
  nbColsGrid: propOr(NB_COLS_GRID, 'nbColsGrid', widget),
  heightRowsGrid: propOr(HEIGHT_ROWS_GRID, 'heightRowsGrid', widget),
  contents: JSON.parse(propOr('[]', 'contents', widget)),
  scope,
  offerId,
  orientation: propOr('portrait', 'orientation', widget),
  frameStyle: propOr(BASE_FRAME_STYLE, 'frameStyle', widget)
});

const propsAreDefined = (props) => (object) => reduce((acc, prop) => {
  return acc && !propSatisfies(isNilOrEmpty, prop)(object);
}, true)(props);

const saveImages = async ({ contents }) => {
  return await Promise.all(contents.map(async (content) => {
    const {
      type,
      element
    } = content;
    if (type === 'image' && isNil(path([0, 'fileId'], element))) {
      const uploadImage = compose(propOr({}, 'uploadImage'), head)(element);
      if (isEmpty(uploadImage)) {
        message.error('Pour sauvegarder le widget, veuillez ajouter une image.');
        return;
      } else {
        const { fileId } = await uploadFiles(uploadImage);
        const imageSrc = `/files/${fileId}`;
        const newElement = compose(
          assoc('fileId', fileId),
          assoc('imageSrc', imageSrc)
        )(head(element));
        content = {
          ...content,
          element: [newElement]
        };
      }
    }
    return content;
  }));
};

export default useWidget;
