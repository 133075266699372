import React from 'react';
import { Modal } from 'antd';
import WidgetForm from '../widget/WidgetForm';
import { useDocumentContext } from '../../../../context/Document';

const CreateWidgetModal = ({ totalPaddingX }) => {
  const {
    isOpenCreateWidgetModal,
    setIsOpenCreateWidgetModal,
    offerId,
    documentId,
    scope,
    setWidgetCreated,
    globalVariables,
    setGlobalVariables
  } = useDocumentContext();

  const onCancel = () => setIsOpenCreateWidgetModal(false);
  return (
    <Modal
      visible={isOpenCreateWidgetModal}
      footer={null}
      onCancel={onCancel}
      width="90%"
      destroyOnClose
    >
      <WidgetForm
        scope={scope}
        onCancel={onCancel}
        modalMode
        documentId={documentId}
        offerId={offerId}
        totalPaddingX={totalPaddingX}
        setWidgetCreated={setWidgetCreated}
        globalVariables={globalVariables}
        setGlobalVariables={setGlobalVariables}
      />
    </Modal>
  );
};

export default CreateWidgetModal;
