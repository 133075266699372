/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QDefaultFontQueryVariables = {|
  teamId: string
|};
export type QDefaultFontQueryResponse = {|
  +defaultFont: ?{|
    +id: ?string,
    +teamId: ?string,
    +value: ?string,
    +fontSize: ?number,
    +unit: ?string,
  |}
|};
export type QDefaultFontQuery = {|
  variables: QDefaultFontQueryVariables,
  response: QDefaultFontQueryResponse,
|};
*/


/*
query QDefaultFontQuery(
  $teamId: ID!
) {
  defaultFont(teamId: $teamId) {
    id
    teamId
    value
    fontSize
    unit
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "teamId",
        "variableName": "teamId"
      }
    ],
    "concreteType": "DefaultFont",
    "kind": "LinkedField",
    "name": "defaultFont",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "teamId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fontSize",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "unit",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QDefaultFontQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QDefaultFontQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9601e682364465d40041216543d40550",
    "id": null,
    "metadata": {},
    "name": "QDefaultFontQuery",
    "operationKind": "query",
    "text": "query QDefaultFontQuery(\n  $teamId: ID!\n) {\n  defaultFont(teamId: $teamId) {\n    id\n    teamId\n    value\n    fontSize\n    unit\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5c78cd6810d4fc9d527b0d1e07a3fc3a';

module.exports = node;
