import React from 'react';
import { prop } from 'ramda';
import VersionsTree from '../../../components/VersionTree/VersionsTree';
import { associateVersions } from '../../../lib/versions';
import withUser from '../../../withUser';

const OfferList = ({
  style,
  userCanEdit,
  offers,
  setRefetch,
  user
}) => {
  return (
    // eslint-disable-next-line no-restricted-syntax
    <div style={style}>
      <VersionsTree
        items={associateVersions(offers, 'version.itemRootId')}
        editLinkPattern="/offre/:itemId/edition"
        suppressionLabel="Voulez-vous vraiment supprimer cette offre ?"
        archiveLabel="Voulez-vous vraiment archiver cette offre ?"
        userCanEdit={userCanEdit}
        nameField="offerName"
        setRefetch={setRefetch}
        userId={prop('userId', user)}
        isOffer
      />
    </div>
  );
};

OfferList.defaultProps = { style: { marginTop: 16 } };

export default withUser(OfferList);
