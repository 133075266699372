import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import React, { memo, useState } from 'react';
import withUser from '../../../withUser';

const ImportButton = ({ userIsAdmin, menu, className }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      disabled={!userIsAdmin}
      onVisibleChange={(visible) => setDropdownVisible(visible)}
      className={className}
    >
      <Button
        className="btn-import-model flex items-center justify-center"
        type="primary"
        disabled={!userIsAdmin}
      >
        Importer
        <DownOutlined
          className={`ml-2 transition-transform duration-200 ${
            dropdownVisible ? 'rotate-180' : ''
          }`}
        />
      </Button>
    </Dropdown>
  );
};

export default memo(withUser(ImportButton));
