import { commitMutation,
  graphql } from 'react-relay';
import environment from '../../.';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
    mutation UpdateDefaultFontMutation($teamId: ID!, $value: String!, $fontSize: Int!, $unit: String!) {
      updateDefaultFont(teamId: $teamId, value: $value, fontSize: $fontSize, unit: $unit) {
        ok
        error
      }
    }
  `;

export default ({
  teamId,
  value,
  fontSize,
  unit
}, done) => {
  const variables = {
    teamId,
    value,
    fontSize,
    unit
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ updateDefaultFont }) => basicMutationOnCompleted(updateDefaultFont, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
