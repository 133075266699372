import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '../../index';
import { populateChildren } from '../toolbox';

export const QGlobalVariablesQuery = graphql`
  query QGlobalVariablesQuery($scope: DocumentScopeEnum!, $offerId: ID, $checkUsage: Boolean) {
    globalVariables(scope: $scope, offerId: $offerId, checkUsage: $checkUsage) {
      id
      name
      type
      dates {
        creation
        lastUpdate
      }
      isUsed
    }
  }
`;

const QGlobalVariables = ({
  children,
  childDataProp,
  args
}) => (
  <Query
    query={QGlobalVariablesQuery}
    args={args}
  >
    {populateChildren('globalVariables')(children, childDataProp)}
  </Query>
);

export default QGlobalVariables;
