import React, { useEffect, useState } from 'react';
import { Button, Select, Tooltip, Switch } from 'antd';
import { DeleteOutlined, DownloadOutlined, EyeOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { forEach, pathOr, prop, propOr, equals, filter, propEq, any, compose, flip, includes, map, assocPath } from 'ramda';
import { useDocumentContext } from '../../../../../context/Document';
import DocumentSections from '../DocumentSections';
import { SCOPE_OFFER } from '../../../../../_CONST';
import SelectOrientation from '../../widget/SelectOrientation';
import DocumentActions from '../DocumentActions';
import Input from '../Input';
import withUser from '../../../../../withUser';
import { mapIndexed } from '../../../../../utils/mapIndexed';
import ConfirmButton from '../../../../../components/ConfirmButton';
import PaddingInfo from './PaddingInfo';
import DocumentConfig from './createTab/DocumentConfig';

const Title = ({
  children,
  className
}) => {
  return (
    <div className={`mt-8 ${className}`}>
      <span className="text-xl uppercase text-flexibranche-blue font-bold">{children}</span>
    </div>
  );
};

const CreateTab = (props) => {
  const {
    userIsAdmin,
    dataItemPrefix,
    widgets
  } = props;

  const {
    scope,
    values,
    setHasLandscapeWidget,
    isEdit,
    setState,
    hasLandscapeWidget,
    updatePaddingDocument,
    previewDocumentHtml,
    previewHtmlLoading,
    onSave,
    nameIsDefined,
    loading,
    onSaveAndQuit,
    onDelete,
    deleteLoading,
    downloadStructuredHtml,
    downloadStructuredHtmlLoading
  } = useDocumentContext();

  const [showRolesSettings, setShowRolesSettings] = useState(false);
  const isScopeOffer = equals(scope, SCOPE_OFFER);
  const filterWidgetsByOrientation = (orientation) => filter(propEq('orientation', orientation));
  const landscapeWidgets = filterWidgetsByOrientation('landscape')(widgets);
  const usedWidgets = [];
  const extractWidgets = (sectionIndex, widgetType) => pathOr([], ['pages', sectionIndex, widgetType], values);
  mapIndexed((section, sectionIndex) => {
    const getUsedWidgets = (array) => {
      forEach(widgetRow => {
        const usedWidgetsRow = prop('widgets', widgetRow);
        forEach(usedWidget => {
          usedWidgets.push(usedWidget);
        })(usedWidgetsRow);
      })(array);
    };
    const extractWidgetsInSection = (widgetType) => extractWidgets(sectionIndex, widgetType);

    const widgetsHeader = extractWidgetsInSection('header');
    getUsedWidgets(widgetsHeader);
    const widgetsSection = extractWidgetsInSection('widgets');
    getUsedWidgets(widgetsSection);
    const widgetsFooter = extractWidgetsInSection('footer');
    getUsedWidgets(widgetsFooter);
  })(propOr([], 'pages', values));

  useEffect(() => {
    const documentHasLandscapeWidget = any(
      compose(
        flip(includes)(map(prop('id'), landscapeWidgets)),
        prop('widgetId')
      ),
      usedWidgets
    );

    setHasLandscapeWidget(documentHasLandscapeWidget);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landscapeWidgets]);

  const canDelete = isEdit && userIsAdmin;

  return (
    <div>
      {userIsAdmin && isScopeOffer && isEdit && (
        <>
          <button
            onClick={() => setShowRolesSettings(!showRolesSettings)}
            className="float-right text-flexibranche-lightblue font-semibold"
          >
            {showRolesSettings ? 'Masquer' : 'Afficher'}
          </button>
          <Title>
            Configuration des rôles
          </Title>

          {showRolesSettings && (
            <DocumentConfig/>
          )}
        </>
      )}

      <Title>Nom du document</Title>
      <div className="inline-flex w-full justify-center align-middle items-center space-x-1">
        <Input
          placeholder="Nom du document"
          onChange={value => setState(assocPath(['values', 'name'], value))}
          value={prop('name', values)}
          data-item-path={dataItemPrefix}
          data-item-key="name"
          className="w-full mt-2"
          disabled={!userIsAdmin}
          debounce
        />
        <div className="mt-2">
          <SelectOrientation
            defaultOrientation={propOr('portrait', 'orientation', values)}
            changeOrientation={e => setState(assocPath(['values', 'orientation'], e))}
            currentOrientation={propOr('portrait', 'orientation', values)}
            hasLandscapeWidget={hasLandscapeWidget}
            disabled={!userIsAdmin}
          />
        </div>
      </div>
      <div className="inline-flex">
        <div className="mt-2 mr-2">
          <Tooltip title="Version du moteur de rendu" color="#0197DC" overlayStyle={{ maxWidth: '200px' }}>
            <Select
              defaultValue={propOr('v2', 'renderingEngineVersion', values)}
              onChange={e => setState(assocPath(['values', 'renderingEngineVersion'], e))}
              className="w-120px"
              options={[
                { value: 'v1', label: 'Version 1' },
                { value: 'v2', label: 'Version 2' }
              ]}
              disabled={!userIsAdmin}
            />
          </Tooltip>
        </div>
        <div className="mt-2 mr-2">
          <Tooltip title="Format" color="#0197DC" overlayStyle={{ maxWidth: '120px' }}>
            <Select
              defaultValue={propOr('PDF', 'formatPdf', values)}
              onChange={e => setState(assocPath(['values', 'formatPdf'], e))}
              className="w-120px"
              options={[
                { value: 'PDF', label: 'PDF' },
                { value: 'A_1a', label: 'PDF/A-1b' },
                { value: 'A_2b', label: 'PDF/A-2b' },
                { value: 'A_3b', label: 'PDF/A-3b' }
              ]}
              disabled={!userIsAdmin}
            />
          </Tooltip>
        </div>
      </div>
      <div className="mt-2 flex items-center">
        <label className="mr-2">Document HDS</label>
        <Switch
          checked={prop('isHDS', values)}
          onChange={e => setState(assocPath(['values', 'isHDS'], e))}
          disabled={!userIsAdmin}
        />
      </div>

      <Title className="mb-2">Composition du document</Title>

      <PaddingInfo updatePadding={updatePaddingDocument} element={values}/>

      <DocumentSections {...props} isEdit={isEdit} />

      <DocumentActions userIsAdmin={userIsAdmin} />

      <div className="items-stretch mt-10 w-full">
        <div className="flex">
          <Button
            className="w-1/2 mb-2 action-btn-preview"
            size="large"
            onClick={previewDocumentHtml}
            loading={previewHtmlLoading}
            icon={<EyeOutlined/>}
          >
            Prévisualiser
          </Button>
          <Button
            className="w-1/2 mb-2 action-btn-download-html"
            size="large"
            onClick={downloadStructuredHtml}
            loading={downloadStructuredHtmlLoading}
            icon={<DownloadOutlined/>}
          >
            Export HTML
          </Button>
        </div>
        {userIsAdmin && (
          <div className="flex">
            <Button
              className="w-1/2 mb-2 action-btn-save"
              type="primary"
              size="large"
              onClick={onSave}
              disabled={!nameIsDefined}
              loading={loading}
              icon={<ThunderboltOutlined/>}
            >
              <span className="font-semibold">{isEdit ? 'Sauvegarder' : 'Créer'}</span>
            </Button>

            <Button
              className="w-1/2 mb-2 action-btn-save-quit"
              type="primary"
              size="large"
              onClick={onSaveAndQuit}
              disabled={!nameIsDefined}
              loading={loading}
            >
              <span className="font-semibold">{isEdit ? 'Sauvegarder' : 'Créer'} et quitter</span>
            </Button>

          </div>
        )}

      </div>
      {canDelete && (
        // Attention, le décalage est souhaité afin de marquer visuellement une séparation
        <div className="items-stretch mt-2 w-full">
          <ConfirmButton
            title="Êtes-vous sûr de vouloir supprimer ce document ?"
            onConfirm={onDelete}
            disabled={deleteLoading}
            loading={deleteLoading}
            icon={<DeleteOutlined/>}
            buttonText="Supprimer le document"
            buttonType="danger"
            className="w-full mb-2"
            sizeButton="large"
          />
        </div>
      )}
    </div >
  );
};

export default withUser(CreateTab);
