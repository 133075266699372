import React, { useRef, useState } from 'react';
import { compose, equals, filter, flatten, includes, isEmpty, map, path, prop } from 'ramda';
import { isNotEmpty } from 'ramda-adjunct';
import classNames from 'classnames';
import { Menu as MenuAntd } from 'antd';
import Layout from '../../layout/default';
import Menu from '../../containers/menu';
import useFetchQuery from '../../hooks/useFetchQuery';
import { QGetDocumentModelsQuery } from '../../_graphql/queries/documents/QGetDocumentModels';
import Loading from '../../components/Loading';
import { Breadcrumb } from '../../components';
import { useBreadcrumb } from '../../hooks';
import { home } from '../helpers/breadCrumb';
import { QOffersQuery } from '../../_graphql/queries/QOffers';
import withUser from '../../withUser';
import FullscreenLoading from '../../components/FullscreenLoading';
import ModalPreviewDocument from '../docs/components/document/ModalPreviewDocument';
import DuplicateDocumentAndItsWidgets from '../../_graphql/mutations/documents/DuplicateDocumentAndItsWidgets';
import { errorMessage, successMessage } from '../../utils/messageMutation';
import PreviewDocumentHtmlMutation from '../../_graphql/mutations/documents/PreviewDocumentHtmlMutation';
import { getClassNameOrientation } from '../../utils/getClassNameOrientation';
import Card from './components/Card';
import ImportButton from './components/ImportButton';

const Models = ({ userTeam }) => {
  const [fullscreenLoading, setFullscreenLoading] = useState(false);
  const [isModalPreviewVisible, setIsModalPreviewVisible] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [isLoadingHtml, setIsLoadingHtml] = useState(false);
  const modalDataCache = useRef({});

  const breadcrumbModels = ['/modeles', 'Mes modèles'];
  const routes = [['/modeles', [home, breadcrumbModels]]];
  const breadcrumb = useBreadcrumb(routes, home);

  const { getDocumentModels: models, isLoading, reFetch } = useFetchQuery({
    query: QGetDocumentModelsQuery,
    dataProp: 'getDocumentModels',
    defaultData: []
  });

  const { offers, isLoading: isLoadingOffers } = useFetchQuery({
    query: QOffersQuery,
    dataProp: 'offers',
    defaultData: [],
    args: { filter: { status: 'draft', team: prop('teamId', userTeam) } }
  });

  const splitPagesByBreak = (htmlArray) => {
    const parser = new DOMParser();
    return compose(
      flatten,
      map(map((div) => prop('outerHTML', div))),
      map(filter((div) => {
        const style = div.getAttribute('style');
        return style && includes('break-after:page', style);
      })),
      map((doc) => Array.from(path(['body', 'children'], doc))),
      map((html) => parser.parseFromString(html, 'text/html'))
    )(htmlArray);
  };

  const handlePreview = (cardData) => () => {
    const { orientation, pages, variables, padding, id, renderingEngineVersion, teamId } = cardData;

    setIsModalPreviewVisible(true);

    const existingModalData = prop(id, modalDataCache.current);
    if (existingModalData) {
      setModalData(existingModalData);
      return;
    }

    setIsLoadingHtml(true);

    PreviewDocumentHtmlMutation(
      {
        pages,
        variables: variables || '{}',
        documentId: id,
        padding,
        orientation,
        renderingEngineVersion,
        isVariablesHighlighted: false,
        teamId,
        isModelPreview: true
      },
      (ok, error, html) => {
        setIsLoadingHtml(false);

        if (ok && !error) {
          const modifiedHtml = splitPagesByBreak(html);
          const classNameOrientationDocument = getClassNameOrientation(orientation);

          const newModalData = {
            id,
            teamId,
            pagesHtml: modifiedHtml,
            classNameOrientationDocument
          };

          modalDataCache.current[id] = newModalData;
          setModalData(newModalData);
        } else {
          errorMessage();
        }
      }
    );
  };

  const handleDuplicateDocument = (documentId, teamId) => (value) => {
    const targetOffer = path(['key'], value);
    setFullscreenLoading(true);
    DuplicateDocumentAndItsWidgets({
      documentId, offerId: targetOffer, teamId
    }, (ok, error) => {
      if (ok && !error) {
        successMessage('document', 'dupliqué');
        if (equals(prop('teamId', userTeam), teamId)) reFetch();
      } else {
        errorMessage();
      }
      setFullscreenLoading(false);
    });
  };

  const renderDuplicateButton = (onClick, isModal) => {
    const menu = (
      <MenuAntd onClick={onClick}>
        {map(({ id, offerName, version }) => <MenuAntd.Item key={id} value={id}>{offerName} - {prop('major', version)}.{prop('minor', version)}</MenuAntd.Item>)(offers)}
      </MenuAntd>
    );

    return <ImportButton menu={menu} className={classNames({ 'mr-2': isModal })} />;
  };

  return (
    <Layout menu={<Menu />}>
      <Breadcrumb value={breadcrumb}/>
      {(isLoading || isLoadingOffers) ? (
        <Loading />
      ) : (
        <>
          {fullscreenLoading && <FullscreenLoading />}
          {isEmpty(models) ? (
            <span>Aucun modèle trouvé.</span>
          ) : (
            <div className="grid grid-cols-4 gap-8 pt-8">{map((model) => {
              return (
                <div key={model.id} className="bg-card-grey p-5 h-520px rounded-xl">
                  <Card
                    name={prop('name', model)}
                    thumbnail={prop('thumbnail', model)}
                    setIsLoadingHtml={setIsLoadingHtml}
                    handlePreview={handlePreview(model)}
                    duplicateButton={isNotEmpty(offers) && renderDuplicateButton(handleDuplicateDocument(prop('id', model), prop('teamId', model)), false)}
                  />
                </div>
              );
            })(models)}
            </div>
          )}
        </>
      )}
      <ModalPreviewDocument
        isModel
        isLoading={isLoadingHtml}
        html={prop('pagesHtml', modalData)}
        hide={() => {
          setIsModalPreviewVisible(false);
          setModalData(null);
        }}
        visible={isModalPreviewVisible}
        classNameOrientationDocument={`${prop('classNameOrientationDocument', modalData)} scale !shadow-none border`}
        buttonToAdd={isNotEmpty(offers) && renderDuplicateButton(handleDuplicateDocument(prop('id', modalData), prop('teamId', modalData)), true)}
      />
    </Layout>
  );
};

export default withUser(Models);
