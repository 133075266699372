import { commitMutation,
  graphql } from 'react-relay';
import environment from '../../.';
import { onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation PreviewDocumentHtmlMutation($pages: [DocumentSectionInput!], $variables: String, $documentId: ID, $padding: PaddingInput, $orientation: OrientationEnum!, $renderingEngineVersion: String, $isVariablesHighlighted: Boolean, $teamId: ID, $isModelPreview: Boolean) {
    previewDocumentHTML(pages: $pages, variables: $variables, documentId: $documentId, padding: $padding, orientation: $orientation, renderingEngineVersion: $renderingEngineVersion, isVariablesHighlighted: $isVariablesHighlighted, teamId: $teamId, isModelPreview: $isModelPreview) {
      ok
      error
      pagesHtml
    }
  }
`;

export default ({
  pages,
  variables,
  documentId,
  padding,
  orientation,
  renderingEngineVersion,
  isVariablesHighlighted,
  teamId,
  isModelPreview
}, done) => {
  variables = JSON.stringify(variables);
  commitMutation(
    environment(),
    {
      mutation,
      variables: {
        pages,
        variables,
        documentId,
        padding,
        orientation,
        renderingEngineVersion,
        isVariablesHighlighted,
        teamId,
        isModelPreview
      },
      onCompleted: ({ previewDocumentHTML }) => {
        const { ok, error, pagesHtml } = previewDocumentHTML;
        done(ok, error, pagesHtml);
      },
      onError: error => onErrorMutation(error, done)
    }
  );
};
