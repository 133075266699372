/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateVersionCommentMutationVariables = {|
  versionId: string,
  comment: string,
|};
export type UpdateVersionCommentMutationResponse = {|
  +updateComment: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type UpdateVersionCommentMutation = {|
  variables: UpdateVersionCommentMutationVariables,
  response: UpdateVersionCommentMutationResponse,
|};
*/


/*
mutation UpdateVersionCommentMutation(
  $versionId: ID!
  $comment: String!
) {
  updateComment(versionId: $versionId, comment: $comment) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "comment"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "versionId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "comment",
        "variableName": "comment"
      },
      {
        "kind": "Variable",
        "name": "versionId",
        "variableName": "versionId"
      }
    ],
    "concreteType": "BasicMutationResponse",
    "kind": "LinkedField",
    "name": "updateComment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateVersionCommentMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateVersionCommentMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "6ca09531d3fe899fd0f3359bd82f1590",
    "id": null,
    "metadata": {},
    "name": "UpdateVersionCommentMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateVersionCommentMutation(\n  $versionId: ID!\n  $comment: String!\n) {\n  updateComment(versionId: $versionId, comment: $comment) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '054c89d233848762e68e05848739a81d';

module.exports = node;
