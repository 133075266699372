/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DocumentRuleActionEnum = "display" | "hide" | "%future added value";
export type DocumentRuleConditionEnum = "isDefined" | "isEqualTo" | "isGreaterThan" | "isLessThan" | "isNotEqualTo" | "isUndefined" | "%future added value";
export type DocumentRuleOperatorEnum = "AND" | "OR" | "%future added value";
export type DocumentWidgetTypeEnum = "content" | "footer" | "header" | "%future added value";
export type DocumentSectionInput = {|
  breakPage?: ?boolean,
  footer?: ?$ReadOnlyArray<?DocumentWidgetHeaderFooterInput>,
  header?: ?$ReadOnlyArray<?DocumentWidgetHeaderFooterInput>,
  padding?: ?PaddingInput,
  rules?: ?$ReadOnlyArray<?DocumentRuleInput>,
  widgets?: ?$ReadOnlyArray<?DocumentWidgetRowInput>,
|};
export type DocumentWidgetHeaderFooterInput = {|
  padding?: ?PaddingInput,
  widgets?: ?$ReadOnlyArray<?DocumentWidgetInput>,
|};
export type PaddingInput = {|
  bottom?: ?number,
  left?: ?number,
  right?: ?number,
  top?: ?number,
|};
export type DocumentWidgetInput = {|
  rules?: ?$ReadOnlyArray<?DocumentRuleInput>,
  type?: ?DocumentWidgetTypeEnum,
  widgetId?: ?string,
|};
export type DocumentRuleInput = {|
  action?: ?DocumentRuleActionEnum,
  conditions?: ?$ReadOnlyArray<?DocumentRuleConditionInput>,
|};
export type DocumentRuleConditionInput = {|
  condition?: ?DocumentRuleConditionEnum,
  operator?: ?DocumentRuleOperatorEnum,
  value?: ?string,
  variable?: ?string,
|};
export type DocumentWidgetRowInput = {|
  breakPage?: ?boolean,
  padding?: ?PaddingInput,
  rulesBreakPage?: ?$ReadOnlyArray<?DocumentRuleInput>,
  widgets?: ?$ReadOnlyArray<?DocumentWidgetInput>,
|};
export type GenerateStructuredHtmlMutationVariables = {|
  offerId?: ?string,
  pages?: ?$ReadOnlyArray<DocumentSectionInput>,
  documentId?: ?string,
  variables?: ?string,
  padding?: ?PaddingInput,
|};
export type GenerateStructuredHtmlMutationResponse = {|
  +generateStructuredHtml: ?{|
    +ok: boolean,
    +error: ?string,
    +html: ?string,
    +filename: ?string,
  |}
|};
export type GenerateStructuredHtmlMutation = {|
  variables: GenerateStructuredHtmlMutationVariables,
  response: GenerateStructuredHtmlMutationResponse,
|};
*/


/*
mutation GenerateStructuredHtmlMutation(
  $offerId: ID
  $pages: [DocumentSectionInput!]
  $documentId: ID
  $variables: String
  $padding: PaddingInput
) {
  generateStructuredHtml(offerId: $offerId, pages: $pages, documentId: $documentId, variables: $variables, padding: $padding) {
    ok
    error
    html
    filename
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "documentId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "offerId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "padding"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pages"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "variables"
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "documentId",
        "variableName": "documentId"
      },
      {
        "kind": "Variable",
        "name": "offerId",
        "variableName": "offerId"
      },
      {
        "kind": "Variable",
        "name": "padding",
        "variableName": "padding"
      },
      {
        "kind": "Variable",
        "name": "pages",
        "variableName": "pages"
      },
      {
        "kind": "Variable",
        "name": "variables",
        "variableName": "variables"
      }
    ],
    "concreteType": "StructuredHtmlMutationResponse",
    "kind": "LinkedField",
    "name": "generateStructuredHtml",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "html",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "filename",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GenerateStructuredHtmlMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "GenerateStructuredHtmlMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "cd7e3fd2d8f8431cbc527da1dc0ed497",
    "id": null,
    "metadata": {},
    "name": "GenerateStructuredHtmlMutation",
    "operationKind": "mutation",
    "text": "mutation GenerateStructuredHtmlMutation(\n  $offerId: ID\n  $pages: [DocumentSectionInput!]\n  $documentId: ID\n  $variables: String\n  $padding: PaddingInput\n) {\n  generateStructuredHtml(offerId: $offerId, pages: $pages, documentId: $documentId, variables: $variables, padding: $padding) {\n    ok\n    error\n    html\n    filename\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e192b4e8aaf06bd43acafa4e7db487a7';

module.exports = node;
