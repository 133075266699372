import { EyeOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { memo } from 'react';
import { base64toBlob } from '../../helpers/base64ToBlob';

const Card = ({ name, thumbnail, duplicateButton, handlePreview }) => {
  const blob = base64toBlob(thumbnail, 'image/png');
  const imgSrc = URL.createObjectURL(blob);
  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-between items-center pb-4">
        <p className="text-base font-bold">{name}</p>
        {duplicateButton}
      </div>
      <div className="relative h-full overflow-auto">
        <img src={imgSrc} alt={name} className="w-full h-full object-cover rounded-lg" />
        <div className="absolute inset-0 bg-white opacity-25 rounded-lg" />
        <Button
          className="btn-modal-thumbnail-model absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 !w-11 !h-11"
          type="primary"
          icon={<EyeOutlined />}
          onClick={handlePreview}
        />
      </div>
    </div>
  );
};

export default memo(Card);
