/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type VersionType = "major" | "minor" | "%future added value";
export type CreateNewItemVersionMutationVariables = {|
  versionId: string,
  type: VersionType,
  comment?: ?string,
|};
export type CreateNewItemVersionMutationResponse = {|
  +createNewItemVersion: ?{|
    +ok: boolean,
    +error: ?string,
    +itemId: ?string,
  |}
|};
export type CreateNewItemVersionMutation = {|
  variables: CreateNewItemVersionMutationVariables,
  response: CreateNewItemVersionMutationResponse,
|};
*/


/*
mutation CreateNewItemVersionMutation(
  $versionId: ID!
  $type: VersionType!
  $comment: String
) {
  createNewItemVersion(versionId: $versionId, type: $type, comment: $comment) {
    ok
    error
    itemId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "comment"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "versionId"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "comment",
        "variableName": "comment"
      },
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      },
      {
        "kind": "Variable",
        "name": "versionId",
        "variableName": "versionId"
      }
    ],
    "concreteType": "CreateNewItemVersionMutationResponse",
    "kind": "LinkedField",
    "name": "createNewItemVersion",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "itemId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateNewItemVersionMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreateNewItemVersionMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "424d816b013b9693b121a8cec1a5da91",
    "id": null,
    "metadata": {},
    "name": "CreateNewItemVersionMutation",
    "operationKind": "mutation",
    "text": "mutation CreateNewItemVersionMutation(\n  $versionId: ID!\n  $type: VersionType!\n  $comment: String\n) {\n  createNewItemVersion(versionId: $versionId, type: $type, comment: $comment) {\n    ok\n    error\n    itemId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a9bbc8b4750169f68e6112d10a63ceb6';

module.exports = node;
