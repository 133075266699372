import { commitMutation,
  graphql } from 'react-relay';
import environment from '../../index';
import { onErrorMutation } from '../common/common';

const mutation = graphql`
mutation CreateNewItemVersionMutation($versionId: ID!, $type: VersionType!, $comment: String) {
  createNewItemVersion(versionId: $versionId, type: $type, comment: $comment) {
    ok
    error
    itemId
  }
}
`;

export default ({ versionId, type, comment }, done) => {
  const variables = {
    versionId,
    type,
    comment
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ createNewItemVersion }) => {
        const { ok, error, itemId } = createNewItemVersion;
        done(ok, error, itemId);
      },
      onError: error => onErrorMutation(error, done)
    }
  );
};
