import { isNil } from 'ramda';
import { commitMutation,
  graphql } from 'react-relay';
import environment from '../../index';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation UpdateVersionCommentMutation($versionId: ID!, $comment: String!) {
    updateComment(versionId: $versionId, comment: $comment) {
      ok
      error
    }
  }
`;

export default ({ versionId, comment, itemId }, done) => {
  const variables = {
    versionId,
    comment
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      updater: (proxyStore, { updateComment }) => {
        const { ok, error } = updateComment;

        if (ok && !error) {
          const item = proxyStore.get(itemId);

          let version;

          if (!isNil(item)) {
            version = item.getLinkedRecord('version');
          } else {
            const storedVersion = proxyStore.get(versionId);

            if (!isNil(storedVersion)) {
              version = storedVersion.getLinkedRecord('version');
            }
          }

          if (!isNil(version)) {
            version.setValue(comment, 'comment');
          }
        }
      },
      onCompleted: ({ updateComment }) => basicMutationOnCompleted(updateComment, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
