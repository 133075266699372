import React, { useState } from 'react';
import { filter } from 'ramda';
import { Button, Modal, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { isNilOrEmpty } from 'ramda-adjunct';
import { errorMessage, successMessage } from '../../../utils/messageMutation';
import CreateNewItemVersionMutation from '../../../_graphql/mutations/versions/CreateNewItemVersionMutation';
import { MAX_VERSION_COMMENT_LENGTH } from '../../../_CONST';

const useCreateVersion = (versionId, setItemId, openStatusModal, onError) => {
  const [loading, setLoading] = useState(false);

  const onCreate = (type, comment) => () => {
    setLoading(true);
    !!openStatusModal && openStatusModal();

    CreateNewItemVersionMutation({
      versionId, type, comment
    }, (ok, error, itemId) => {
      setLoading(false);
      if (ok && !error) {
        successMessage('nouvelle version', 'créée avec succès', false, true);
        setItemId(itemId);
      } else {
        !!onError && onError();
        errorMessage();
      }
    });
  };

  return {
    loading,
    onCreate
  };
};

const ModalCreateVersion = ({
  visible,
  versionId,
  onHide,
  isLastMinor,
  isLastMajor,
  openStatusModal,
  onError,
  setItemId
}) => {
  const { loading, onCreate } = useCreateVersion(
    versionId,
    setItemId,
    openStatusModal,
    onError
  );

  const [comment, setComment] = useState();
  const [versionType, setVersionType] = useState();

  const handleCreate = () => onCreate(versionType, comment)();

  const versionTypeOptions = filter(Boolean)([
    isLastMinor && { value: 'minor', label: 'Mineure' },
    isLastMajor && isLastMinor && { value: 'major', label: 'Majeure' }
  ]);

  return (
    <Modal
      title="Créer une nouvelle version" visible={visible} footer={null}
      onCancel={onHide}
    >
      <Select
        className="w-full mb-4"
        placeholder="Type de version*"
        value={versionType}
        onChange={setVersionType}
        options={versionTypeOptions}
      />

      <TextArea
        className="mb-4"
        placeholder="Commentaire"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        autoSize={{ minRows: 4, maxRows: 6 }}
        maxLength={MAX_VERSION_COMMENT_LENGTH}
      />

      <Button
        onClick={handleCreate}
        type="primary"
        disabled={loading || isNilOrEmpty(versionType)}
      >
        Créer
      </Button>
    </Modal>
  );
};

export default ModalCreateVersion;
