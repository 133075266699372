import { Button, Modal } from 'antd';
import { either, isNil, isEmpty } from 'ramda';
import React from 'react';
import ElementReviewButtons from '../../../../components/reviews/ElementReviewButtons';
import PreviewDocument from './PreviewDocument';

const isNilOrEmpty = either(isNil, isEmpty);

const ModalPreviewDocument = ({
  html = [],
  visible,
  hide,
  isReview,
  offerId,
  documentId,
  isLoading = false,
  classNameOrientationDocument,
  pageHeightDocument,
  isModel,
  buttonToAdd
}) => {
  return (
    <Modal
      closable
      centered
      visible={visible}
      onCancel={hide}
      footer={isModel ? null : [
        <Button
          key="ok"
          type="primary"
          onClick={hide}
        >
          OK
        </Button>
      ]}
      width="auto"
      className="modal-preview-document !w-600px !h-[95vh] overflow-auto rounded-2xl"
      bodyStyle={{ height: '95vh', overflow: 'auto' }}
      modalRender={(modal) => (
        <div className="ant-modal-content h-full flex flex-col">
          {modal}
        </div>
      )}
    >
      {isNilOrEmpty(html) && !isLoading ? <span>Aucun document à afficher.</span> : (
        <>
          {isReview && (
            <ElementReviewButtons
              itemId={documentId}
              itemRootId={offerId}
              itemType="document"
              html={html}
            />
          )}
          <PreviewDocument
            isModel={isModel}
            pagesHtml={html}
            classNameOrientationDocument={classNameOrientationDocument}
            pageHeightDocument={pageHeightDocument}
            activeKey={0}
            previewHtmlLoading={isLoading}
            buttonToAdd={buttonToAdd}
          />
        </>
      )}
    </Modal>
  );
};

export default ModalPreviewDocument;
