/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OfferStatus = "archived" | "draft" | "ready" | "release" | "review" | "%future added value";
export type OffersFilterInput = {|
  status?: ?OfferStatus,
  team?: ?string,
|};
export type QOffersQueryVariables = {|
  filter?: ?OffersFilterInput
|};
export type QOffersQueryResponse = {|
  +offers: ?$ReadOnlyArray<?{|
    +id: ?string,
    +offerName: ?string,
    +offerId: ?string,
    +dates: ?{|
      +creation: ?string,
      +lastUpdate: ?string,
    |},
    +version: ?{|
      +major: ?number,
      +minor: ?number,
      +status: ?string,
      +itemId: ?string,
      +itemRootId: ?string,
      +versionId: ?string,
      +comment: ?string,
    |},
  |}>
|};
export type QOffersQuery = {|
  variables: QOffersQueryVariables,
  response: QOffersQueryResponse,
|};
*/


/*
query QOffersQuery(
  $filter: OffersFilterInput
) {
  offers(filter: $filter) {
    id
    offerName
    offerId
    dates {
      creation
      lastUpdate
    }
    version {
      major
      minor
      status
      itemId
      itemRootId
      versionId
      comment
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "offerName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "offerId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Dates",
  "kind": "LinkedField",
  "name": "dates",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastUpdate",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "major",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minor",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "itemId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "itemRootId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "versionId",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "comment",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QOffersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Offer",
        "kind": "LinkedField",
        "name": "offers",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Version",
            "kind": "LinkedField",
            "name": "version",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QOffersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Offer",
        "kind": "LinkedField",
        "name": "offers",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Version",
            "kind": "LinkedField",
            "name": "version",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f471a42c508b5eb3b0636d13f20d7950",
    "id": null,
    "metadata": {},
    "name": "QOffersQuery",
    "operationKind": "query",
    "text": "query QOffersQuery(\n  $filter: OffersFilterInput\n) {\n  offers(filter: $filter) {\n    id\n    offerName\n    offerId\n    dates {\n      creation\n      lastUpdate\n    }\n    version {\n      major\n      minor\n      status\n      itemId\n      itemRootId\n      versionId\n      comment\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd0d8670864518bbd2abdbcdc5017ba50';

module.exports = node;
