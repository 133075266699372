export const imageUrls = [
  '/images/FASST_Flexi_Blanc.svg',
  '/images/FASST_Flexi_Couleur.svg',
  '/images/background-account-1.svg',
  '/images/background-fill.svg',
  '/images/chevron-left.svg',
  '/images/docs-1@2x.png',
  '/images/ic-menu.svg',
  '/images/ic-menu@3x.png',
  '/images/icon-comparer@3x.png',
  '/images/icon-equipe@3x.png',
  '/images/icon-modeles@3x.png',
  '/images/icon-offre-dark@2x.png',
  '/images/icon-offres@3x.png',
  '/images/icon-tab@3x.png',
  '/images/icone-logo@3x.png',
  '/images/illu-1@2x.png',
  '/images/illu-2-bis@2x.png',
  '/images/illu-equipe@3x.png',
  '/images/illu-new-doc@2x.png',
  '/images/illu-new-widget@2x.png',
  '/images/logo-flexibranches@3x.png',
  '/icons/icon-add-option.svg',
  '/icons/icon-arrow-right-from-bracket.svg',
  '/icons/icon-calendar-check.svg',
  '/icons/icon-comment-quote.svg',
  '/icons/icon-doc.svg',
  '/icons/icon-dropdown-black.svg',
  '/icons/icon-equipe-dark.svg',
  '/icons/icon-fleche.svg',
  '/icons/icon-layer-plus.svg',
  '/icons/icon-ok.svg',
  '/icons/icon-pen-field.png',
  '/icons/icon-pen-to-square.svg'
];
