import React from 'react';
import { Popconfirm, Button } from 'antd';

const ConfirmButton = ({
  title,
  onConfirm,
  disabled = false,
  loading,
  icon,
  buttonText,
  buttonType = 'primary',
  className,
  disabledPopover = false,
  sizeButton = 'default',
  okButtonProps = {},
  okText = 'Oui'
}) => (
  <Popconfirm
    title={title}
    onConfirm={onConfirm}
    okText={okText}
    cancelText="Annuler"
    disabled={disabledPopover || disabled}
    okButtonProps={okButtonProps}
  >
    <Button
      type={buttonType}
      // eslint-disable-next-line no-restricted-syntax
      style={{ height: '45px' }}
      className={`text-sm ${className}`}
      disabled={disabled}
      loading={loading}
      icon={icon}
      onClick={() => disabledPopover && onConfirm()}
      size={sizeButton}
    >
      {buttonText}
    </Button>
  </Popconfirm>
);

export default ConfirmButton;
