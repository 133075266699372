import './less/app.less';
import './less/custom.less';
import './less/teams.less';

import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { addLocaleData } from 'react-intl';
import { CookiesProvider } from 'react-cookie';
import Moment from 'moment';
import { ThemeProvider } from 'styled-components';
import { ConfigProvider } from 'antd/lib';
import frFR from 'antd/es/locale/fr_FR';
import frIntl from 'react-intl/locale-data/fr';
import { logger } from 'redux-logger';
import { RelayEnvironmentProvider } from 'react-relay';
import fr from './intl/fr';
import theme from './theme';
import { getEnvironment } from './_graphql/Environment';
import Routes from './Routes';
import reducers from './reducers';
import Tokens from './lib/Tokens';
import ImagesPreloader from './ImagesPreloader/ImagesPreloader';
import { imageUrls } from './ImagesPreloader/imagesList';

addLocaleData([...frIntl]);

// eslint-disable-next-line
Tokens.setKey(API_USER_ID, API_KEY);

const initialState = {
  intl: {
    defaultLocale: 'fr',
    locale: 'fr',
    messages: fr.messages
  }
};

const store = createStore(
  reducers,
  initialState,
  applyMiddleware(thunkMiddleware, logger)
);

const getDocumentRoot = () => {
  const element = document.createElement('div');
  document.body.appendChild(element);
  return element;
};

Moment.locale('fr');

const App = ({ environment }) => {
  const [imagesLoaded, setImagesLoaded] = useState(false);

  return (
    <>
      {!imagesLoaded && <ImagesPreloader urls={imageUrls} onLoaded={() => setImagesLoaded(true)} />}
      {imagesLoaded && (
        <RelayEnvironmentProvider environment={environment}>
          <ConfigProvider locale={frFR}>
            <ThemeProvider theme={theme}>
              <CookiesProvider>
                <Routes store={store} />
              </CookiesProvider>
            </ThemeProvider>
          </ConfigProvider>
        </RelayEnvironmentProvider>
      )}
    </>
  );
};

(async () => {
  const environment = await getEnvironment();
  ReactDOM.render(<App environment={environment} />, getDocumentRoot());
})();
