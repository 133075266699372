import { useEffect } from 'react';
import { map } from 'ramda';

const ImagesPreloader = ({ urls, onLoaded }) => {
  useEffect(() => {
    let isMounted = true;
    const linkElements = [];

    const preloadImage = (url) => {
      return new Promise((resolve, reject) => {
        const link = document.createElement('link');
        link.rel = 'preload';
        link.as = 'image';
        link.href = url;
        document.head.appendChild(link);
        linkElements.push(link);

        const img = new Image();
        img.src = url;
        img.onload = () => resolve(url);
        img.onerror = () => reject(url);
      });
    };

    const preloadImages = async () => {
      try {
        await Promise.all(map(preloadImage)(urls));
        if (isMounted) {
          onLoaded();
        }
      } catch (error) {
        console.error('Error preloading images:', error);
      }
    };

    preloadImages();

    return () => {
      isMounted = false;
      linkElements.forEach(link => {
        if (link && link.parentNode) {
          link.parentNode.removeChild(link);
        }
      });
    };
  }, [urls, onLoaded]);

  return null;
};

export default ImagesPreloader;
