import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import UpdateVersionCommentMutation from '../../../_graphql/mutations/versions/UpdateVersionCommentMutation';
import { errorMessage, successMessage } from '../../../utils/messageMutation';
import { MAX_VERSION_COMMENT_LENGTH } from '../../../_CONST';

const ModalEditVersionComment = ({
  visible,
  onHide,
  versionId,
  itemId,
  comment: initialComment
}) => {
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState(initialComment);

  useEffect(() => {
    setComment(initialComment);
  }, [initialComment]);

  const handleCreate = () => {
    setLoading(true);
    UpdateVersionCommentMutation({
      versionId, itemId, comment
    }, (ok, error) => {
      setLoading(false);
      if (ok && !error) {
        successMessage('commentaire', 'modifié avec succès', false, false);
        !!onHide && onHide();
      } else {
        errorMessage();
      }
    });
  };

  return (
    <Modal
      title="Modification du commentaire" visible={visible} footer={null}
      onCancel={onHide}
    >
      <TextArea
        className="mb-4"
        placeholder="Commentaire"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        autoSize={{ minRows: 4, maxRows: 6 }}
        maxLength={MAX_VERSION_COMMENT_LENGTH}
      />
      <Button
        loading={loading}
        disabled={loading}
        onClick={handleCreate}
        type="primary"
      >
        Modifier
      </Button>
    </Modal>
  );
};

export default ModalEditVersionComment;
