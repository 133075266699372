/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateDefaultFontMutationVariables = {|
  teamId: string,
  value: string,
  fontSize: number,
  unit: string,
|};
export type UpdateDefaultFontMutationResponse = {|
  +updateDefaultFont: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type UpdateDefaultFontMutation = {|
  variables: UpdateDefaultFontMutationVariables,
  response: UpdateDefaultFontMutationResponse,
|};
*/


/*
mutation UpdateDefaultFontMutation(
  $teamId: ID!
  $value: String!
  $fontSize: Int!
  $unit: String!
) {
  updateDefaultFont(teamId: $teamId, value: $value, fontSize: $fontSize, unit: $unit) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fontSize"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "teamId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "unit"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "value"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "fontSize",
        "variableName": "fontSize"
      },
      {
        "kind": "Variable",
        "name": "teamId",
        "variableName": "teamId"
      },
      {
        "kind": "Variable",
        "name": "unit",
        "variableName": "unit"
      },
      {
        "kind": "Variable",
        "name": "value",
        "variableName": "value"
      }
    ],
    "concreteType": "BasicMutationResponse",
    "kind": "LinkedField",
    "name": "updateDefaultFont",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateDefaultFontMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateDefaultFontMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "789289b4779bfb22492e4d8f995851b9",
    "id": null,
    "metadata": {},
    "name": "UpdateDefaultFontMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateDefaultFontMutation(\n  $teamId: ID!\n  $value: String!\n  $fontSize: Int!\n  $unit: String!\n) {\n  updateDefaultFont(teamId: $teamId, value: $value, fontSize: $fontSize, unit: $unit) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '951500c0ce1897d22871e739bc88a785';

module.exports = node;
