/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DocumentRuleActionEnum = "display" | "hide" | "%future added value";
export type DocumentRuleConditionEnum = "isDefined" | "isEqualTo" | "isGreaterThan" | "isLessThan" | "isNotEqualTo" | "isUndefined" | "%future added value";
export type DocumentRuleOperatorEnum = "AND" | "OR" | "%future added value";
export type DocumentWidgetTypeEnum = "content" | "footer" | "header" | "%future added value";
export type OrientationEnum = "landscape" | "portrait" | "%future added value";
export type QGetDocumentModelsQueryVariables = {||};
export type QGetDocumentModelsQueryResponse = {|
  +getDocumentModels: ?$ReadOnlyArray<?{|
    +id: ?string,
    +name: ?string,
    +teamId: ?string,
    +thumbnail: ?string,
    +variables: ?string,
    +orientation: ?OrientationEnum,
    +renderingEngineVersion: ?string,
    +padding: ?{|
      +top: ?number,
      +right: ?number,
      +bottom: ?number,
      +left: ?number,
    |},
    +dates: ?{|
      +creation: ?string,
      +lastUpdate: ?string,
    |},
    +pages: ?$ReadOnlyArray<?{|
      +breakPage: ?boolean,
      +padding: ?{|
        +top: ?number,
        +bottom: ?number,
        +left: ?number,
        +right: ?number,
      |},
      +widgets: ?$ReadOnlyArray<?{|
        +widgets: ?$ReadOnlyArray<?{|
          +widgetId: ?string,
          +type: ?DocumentWidgetTypeEnum,
          +rules: ?$ReadOnlyArray<?{|
            +action: ?DocumentRuleActionEnum,
            +conditions: ?$ReadOnlyArray<?{|
              +condition: ?DocumentRuleConditionEnum,
              +variable: ?string,
              +value: ?string,
              +operator: ?DocumentRuleOperatorEnum,
            |}>,
          |}>,
        |}>,
        +breakPage: ?boolean,
        +padding: ?{|
          +top: ?number,
          +right: ?number,
          +bottom: ?number,
          +left: ?number,
        |},
      |}>,
      +header: ?$ReadOnlyArray<?{|
        +widgets: ?$ReadOnlyArray<?{|
          +widgetId: ?string,
          +type: ?DocumentWidgetTypeEnum,
          +rules: ?$ReadOnlyArray<?{|
            +action: ?DocumentRuleActionEnum,
            +conditions: ?$ReadOnlyArray<?{|
              +condition: ?DocumentRuleConditionEnum,
              +variable: ?string,
              +value: ?string,
              +operator: ?DocumentRuleOperatorEnum,
            |}>,
          |}>,
        |}>,
        +padding: ?{|
          +top: ?number,
          +right: ?number,
          +bottom: ?number,
          +left: ?number,
        |},
      |}>,
      +footer: ?$ReadOnlyArray<?{|
        +widgets: ?$ReadOnlyArray<?{|
          +widgetId: ?string,
          +type: ?DocumentWidgetTypeEnum,
          +rules: ?$ReadOnlyArray<?{|
            +action: ?DocumentRuleActionEnum,
            +conditions: ?$ReadOnlyArray<?{|
              +condition: ?DocumentRuleConditionEnum,
              +variable: ?string,
              +value: ?string,
              +operator: ?DocumentRuleOperatorEnum,
            |}>,
          |}>,
        |}>,
        +padding: ?{|
          +top: ?number,
          +right: ?number,
          +bottom: ?number,
          +left: ?number,
        |},
      |}>,
    |}>,
  |}>
|};
export type QGetDocumentModelsQuery = {|
  variables: QGetDocumentModelsQueryVariables,
  response: QGetDocumentModelsQueryResponse,
|};
*/


/*
query QGetDocumentModelsQuery {
  getDocumentModels {
    id
    name
    teamId
    thumbnail
    variables
    orientation
    renderingEngineVersion
    padding {
      top
      right
      bottom
      left
    }
    dates {
      creation
      lastUpdate
    }
    pages {
      breakPage
      padding {
        top
        bottom
        left
        right
      }
      widgets {
        widgets {
          widgetId
          type
          rules {
            action
            conditions {
              condition
              variable
              value
              operator
            }
          }
        }
        breakPage
        padding {
          top
          right
          bottom
          left
        }
      }
      header {
        widgets {
          widgetId
          type
          rules {
            action
            conditions {
              condition
              variable
              value
              operator
            }
          }
        }
        padding {
          top
          right
          bottom
          left
        }
      }
      footer {
        widgets {
          widgetId
          type
          rules {
            action
            conditions {
              condition
              variable
              value
              operator
            }
          }
        }
        padding {
          top
          right
          bottom
          left
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "top",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "right",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bottom",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "left",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Padding",
  "kind": "LinkedField",
  "name": "padding",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "breakPage",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "DocumentWidget",
  "kind": "LinkedField",
  "name": "widgets",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "widgetId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DocumentWidgetRule",
      "kind": "LinkedField",
      "name": "rules",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "action",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DocumentWidgetRuleCondition",
          "kind": "LinkedField",
          "name": "conditions",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "condition",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "variable",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "operator",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = [
  (v6/*: any*/),
  (v4/*: any*/)
],
v8 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Document",
    "kind": "LinkedField",
    "name": "getDocumentModels",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "teamId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "thumbnail",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "variables",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "orientation",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "renderingEngineVersion",
        "storageKey": null
      },
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "WidgetDates",
        "kind": "LinkedField",
        "name": "dates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "creation",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastUpdate",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DocumentSection",
        "kind": "LinkedField",
        "name": "pages",
        "plural": true,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Padding",
            "kind": "LinkedField",
            "name": "padding",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DocumentWidgetRow",
            "kind": "LinkedField",
            "name": "widgets",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              (v5/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DocumentWidgetHeaderFooter",
            "kind": "LinkedField",
            "name": "header",
            "plural": true,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DocumentWidgetHeaderFooter",
            "kind": "LinkedField",
            "name": "footer",
            "plural": true,
            "selections": (v7/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QGetDocumentModelsQuery",
    "selections": (v8/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QGetDocumentModelsQuery",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "4ca5144b856fff6fd7e02681482f6e61",
    "id": null,
    "metadata": {},
    "name": "QGetDocumentModelsQuery",
    "operationKind": "query",
    "text": "query QGetDocumentModelsQuery {\n  getDocumentModels {\n    id\n    name\n    teamId\n    thumbnail\n    variables\n    orientation\n    renderingEngineVersion\n    padding {\n      top\n      right\n      bottom\n      left\n    }\n    dates {\n      creation\n      lastUpdate\n    }\n    pages {\n      breakPage\n      padding {\n        top\n        bottom\n        left\n        right\n      }\n      widgets {\n        widgets {\n          widgetId\n          type\n          rules {\n            action\n            conditions {\n              condition\n              variable\n              value\n              operator\n            }\n          }\n        }\n        breakPage\n        padding {\n          top\n          right\n          bottom\n          left\n        }\n      }\n      header {\n        widgets {\n          widgetId\n          type\n          rules {\n            action\n            conditions {\n              condition\n              variable\n              value\n              operator\n            }\n          }\n        }\n        padding {\n          top\n          right\n          bottom\n          left\n        }\n      }\n      footer {\n        widgets {\n          widgetId\n          type\n          rules {\n            action\n            conditions {\n              condition\n              variable\n              value\n              operator\n            }\n          }\n        }\n        padding {\n          top\n          right\n          bottom\n          left\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '39c7cae8da3c32ff6a3e57efc6362b9f';

module.exports = node;
