import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '../../index';
import { populateChildren } from '../toolbox';

export const QGetDocumentModelsQuery = graphql`
  query QGetDocumentModelsQuery {
    getDocumentModels {
      id
      name
      teamId
      thumbnail
      variables
      orientation
      renderingEngineVersion
      padding {
        top
        right
        bottom
        left
      }
      dates {
        creation
        lastUpdate
      }
      pages {
        breakPage
        padding {
          top
          bottom
          left
          right
        }
        widgets {
          widgets {
            widgetId
            type
            rules {
              action
              conditions {
                condition
                variable
                value
                operator
              }
            }
          }
          breakPage
          padding {
            top
            right
            bottom
            left
          }
        }
        header {
          widgets {
            widgetId
            type
            rules {
              action
              conditions {
                condition
                variable
                value
                operator
              }
            }
          }
          padding {
            top
            right
            bottom
            left
          }
        }
        footer {
          widgets {
            widgetId
            type
            rules {
              action
              conditions {
                condition
                variable
                value
                operator
              }
            }
          }
          padding {
            top
            right
            bottom
            left
          }
        }
      }
    }
  }
`;

const QDocumentModels = ({
  children,
  childDataProp,
  args
}) => {
  return (
    <Query
      query={QGetDocumentModelsQuery}
      args={args}
    >
      {populateChildren()(children, childDataProp)}
    </Query>
  );
};

export default QDocumentModels;
