import { Button, Input, message, Radio, Select } from 'antd';
import { assoc, compose, path, prop } from 'ramda';
import React, { useEffect, useState } from 'react';
import { Actions, ChevronBottom, Section, Title } from '../../../components';
import { fontOptions } from '../common/FontOptions';
import withUser from '../../../withUser';
import Loading from '../../../components/Loading';
import { errorMessage } from '../../../utils/messageMutation';
import UpdateDefaultFontMutation from '../../../_graphql/mutations/documents/UpdateDefaultFontMutation';
import useDefaultFont from '../../../hooks/useDefaultFont';

const DefaultFont = ({ userTeam }) => {
  const {
    defaultFont,
    isLoading
  } = useDefaultFont({ teamId: prop('teamId', userTeam) });

  const [values, setValues] = useState({
    value: 'Arial',
    fontSize: 12,
    unit: 'px'
  });

  useEffect(() => {
    if (!isLoading) {
      setValues(
        compose(
          assoc('value', prop('value', defaultFont)),
          assoc('fontSize', prop('fontSize', defaultFont)),
          assoc('unit', prop('unit', defaultFont))
        )(values)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  if (isLoading) return <Loading />;

  const onSave = () => {
    const callback = (ok, error) => {
      if (ok && !error) {
        message.success(
          'Police par défaut sauvegardée.'
        );
      } else {
        errorMessage();
      }
    };

    UpdateDefaultFontMutation({ teamId: prop('teamId', userTeam), ...values }, callback);
  };

  return (
    <>
      <Actions
        title={<Title title="Ma police par défaut" backTo="/equipe" />}
      />
      <Section>
        <div className="grid grid-cols-3 gap-8">
          <div>
            <label className="mb-2 block font-bold">Police de texte</label>
            <Select
              value={prop('value', values)}
              onChange={(value) => setValues(assoc('value', value))}
              suffixIcon={<ChevronBottom />}
              className="w-full"
            >
              {fontOptions}
            </Select>
          </div>
          <div>
            <label className="mb-2 block font-bold">Taille de la police</label>
            <Input
              type="number"
              value={prop('fontSize', values)}
              onChange={(e) => setValues(assoc('fontSize', parseInt(path(['target', 'value'], e), 10)))}
              max={36}
              min={8}
            />
          </div>
          <div>
            <label className="mb-2 block font-bold">Unité de la police</label>
            <Radio.Group onChange={(e) => setValues(assoc('unit', path(['target', 'value'], e)))} value={prop('unit', values)} className="radio-unit-fontsize">
              <Radio value="px">px</Radio>
              <Radio value="pt">pt</Radio>
            </Radio.Group>
          </div>
        </div>
      </Section>
      <Button
        size="large"
        className="mx-auto"
        type="primary"
        onClick={onSave}
      >
        Enregistrer
      </Button>
    </>
  );
};

export default withUser(DefaultFont);
