import { commitMutation,
  graphql } from 'react-relay';
import environment from '../..';
import { onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation GenerateStructuredHtmlMutation($offerId: ID, $pages: [DocumentSectionInput!], $documentId: ID, $variables: String, $padding: PaddingInput) {
    generateStructuredHtml(offerId: $offerId, pages: $pages, documentId: $documentId, variables: $variables, padding: $padding) {
      ok
      error
      html
      filename
    }
  }
`;

export default ({
  offerId,
  pages,
  documentId,
  variables,
  padding
}, done) => {
  variables = JSON.stringify(variables);
  commitMutation(
    environment(),
    {
      mutation,
      variables: {
        offerId,
        pages,
        documentId,
        variables,
        padding
      },
      onCompleted: ({ generateStructuredHtml }) => {
        const { ok, error, html, filename } = generateStructuredHtml;
        done(ok, error, html, filename);
      },
      onError: error => onErrorMutation(error, done)
    }
  );
};
