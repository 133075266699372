/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DocumentRuleActionEnum = "display" | "hide" | "%future added value";
export type DocumentRuleConditionEnum = "isDefined" | "isEqualTo" | "isGreaterThan" | "isLessThan" | "isNotEqualTo" | "isUndefined" | "%future added value";
export type DocumentRuleOperatorEnum = "AND" | "OR" | "%future added value";
export type DocumentWidgetTypeEnum = "content" | "footer" | "header" | "%future added value";
export type OrientationEnum = "landscape" | "portrait" | "%future added value";
export type DocumentSectionInput = {|
  breakPage?: ?boolean,
  footer?: ?$ReadOnlyArray<?DocumentWidgetHeaderFooterInput>,
  header?: ?$ReadOnlyArray<?DocumentWidgetHeaderFooterInput>,
  padding?: ?PaddingInput,
  rules?: ?$ReadOnlyArray<?DocumentRuleInput>,
  widgets?: ?$ReadOnlyArray<?DocumentWidgetRowInput>,
|};
export type DocumentWidgetHeaderFooterInput = {|
  padding?: ?PaddingInput,
  widgets?: ?$ReadOnlyArray<?DocumentWidgetInput>,
|};
export type PaddingInput = {|
  bottom?: ?number,
  left?: ?number,
  right?: ?number,
  top?: ?number,
|};
export type DocumentWidgetInput = {|
  rules?: ?$ReadOnlyArray<?DocumentRuleInput>,
  type?: ?DocumentWidgetTypeEnum,
  widgetId?: ?string,
|};
export type DocumentRuleInput = {|
  action?: ?DocumentRuleActionEnum,
  conditions?: ?$ReadOnlyArray<?DocumentRuleConditionInput>,
|};
export type DocumentRuleConditionInput = {|
  condition?: ?DocumentRuleConditionEnum,
  operator?: ?DocumentRuleOperatorEnum,
  value?: ?string,
  variable?: ?string,
|};
export type DocumentWidgetRowInput = {|
  breakPage?: ?boolean,
  padding?: ?PaddingInput,
  rulesBreakPage?: ?$ReadOnlyArray<?DocumentRuleInput>,
  widgets?: ?$ReadOnlyArray<?DocumentWidgetInput>,
|};
export type PreviewDocumentHtmlMutationVariables = {|
  pages?: ?$ReadOnlyArray<DocumentSectionInput>,
  variables?: ?string,
  documentId?: ?string,
  padding?: ?PaddingInput,
  orientation: OrientationEnum,
  renderingEngineVersion?: ?string,
  isVariablesHighlighted?: ?boolean,
  teamId?: ?string,
  isModelPreview?: ?boolean,
|};
export type PreviewDocumentHtmlMutationResponse = {|
  +previewDocumentHTML: ?{|
    +ok: boolean,
    +error: ?string,
    +pagesHtml: ?$ReadOnlyArray<?string>,
  |}
|};
export type PreviewDocumentHtmlMutation = {|
  variables: PreviewDocumentHtmlMutationVariables,
  response: PreviewDocumentHtmlMutationResponse,
|};
*/


/*
mutation PreviewDocumentHtmlMutation(
  $pages: [DocumentSectionInput!]
  $variables: String
  $documentId: ID
  $padding: PaddingInput
  $orientation: OrientationEnum!
  $renderingEngineVersion: String
  $isVariablesHighlighted: Boolean
  $teamId: ID
  $isModelPreview: Boolean
) {
  previewDocumentHTML(pages: $pages, variables: $variables, documentId: $documentId, padding: $padding, orientation: $orientation, renderingEngineVersion: $renderingEngineVersion, isVariablesHighlighted: $isVariablesHighlighted, teamId: $teamId, isModelPreview: $isModelPreview) {
    ok
    error
    pagesHtml
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "documentId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isModelPreview"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isVariablesHighlighted"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orientation"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "padding"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pages"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "renderingEngineVersion"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "teamId"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "variables"
},
v9 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "documentId",
        "variableName": "documentId"
      },
      {
        "kind": "Variable",
        "name": "isModelPreview",
        "variableName": "isModelPreview"
      },
      {
        "kind": "Variable",
        "name": "isVariablesHighlighted",
        "variableName": "isVariablesHighlighted"
      },
      {
        "kind": "Variable",
        "name": "orientation",
        "variableName": "orientation"
      },
      {
        "kind": "Variable",
        "name": "padding",
        "variableName": "padding"
      },
      {
        "kind": "Variable",
        "name": "pages",
        "variableName": "pages"
      },
      {
        "kind": "Variable",
        "name": "renderingEngineVersion",
        "variableName": "renderingEngineVersion"
      },
      {
        "kind": "Variable",
        "name": "teamId",
        "variableName": "teamId"
      },
      {
        "kind": "Variable",
        "name": "variables",
        "variableName": "variables"
      }
    ],
    "concreteType": "HtmlMutationResponse",
    "kind": "LinkedField",
    "name": "previewDocumentHTML",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pagesHtml",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PreviewDocumentHtmlMutation",
    "selections": (v9/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v8/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/),
      (v2/*: any*/),
      (v7/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "PreviewDocumentHtmlMutation",
    "selections": (v9/*: any*/)
  },
  "params": {
    "cacheID": "8272fe27c68bdb897eb46cf473bfb691",
    "id": null,
    "metadata": {},
    "name": "PreviewDocumentHtmlMutation",
    "operationKind": "mutation",
    "text": "mutation PreviewDocumentHtmlMutation(\n  $pages: [DocumentSectionInput!]\n  $variables: String\n  $documentId: ID\n  $padding: PaddingInput\n  $orientation: OrientationEnum!\n  $renderingEngineVersion: String\n  $isVariablesHighlighted: Boolean\n  $teamId: ID\n  $isModelPreview: Boolean\n) {\n  previewDocumentHTML(pages: $pages, variables: $variables, documentId: $documentId, padding: $padding, orientation: $orientation, renderingEngineVersion: $renderingEngineVersion, isVariablesHighlighted: $isVariablesHighlighted, teamId: $teamId, isModelPreview: $isModelPreview) {\n    ok\n    error\n    pagesHtml\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2f758397136a6a43766c579f42317f6f';

module.exports = node;
