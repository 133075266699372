import React, { useEffect, useState } from 'react';
import { either, isNil, isEmpty, dec, inc, compose, length, defaultTo, nth } from 'ramda';
import { Button } from 'antd';
import { EyeOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import DocumentWrapper from './DocumentWrapper';

const isNilOrEmpty = either(isNil, isEmpty);

const PaginationButton = ({ disabled, children, onClick, className, isModel }) => {
  return (
    <Button
      className={classNames(
        className,
        { 'w-9 !h-9 !bg-white rounded-md border border-solid !border-flexibranche-wildsand': !isModel },
        { '!border-none !bg-flexibranche-wildsand px-2': isModel },
        { 'text-flexibranche-grey cursor-default': disabled },
        { 'text-[#0197DC] cursor-pointer': !disabled && !isModel },
        { 'text-black cursor-pointer': !disabled && isModel }
      )}
      onClick={onClick}
      disabled={disabled}
      type="text"
    >
      {children}
    </Button>
  );
};

const Pagination = ({
  numberOfPages,
  currentPageIndex,
  setCurrentPageIndex,
  isModel
}) => {
  if (numberOfPages === 0) {
    return null;
  }

  return (
    <div className={classNames('flex items-center mr-4', { 'gap-x-0': isModel }, { 'gap-x-2': !isModel })}>
      <PaginationButton
        className={`previous-section-button ${isModel && '!rounded-none !rounded-l-2xl'}`}
        disabled={currentPageIndex <= 0}
        onClick={() => setCurrentPageIndex(dec)}
        isModel={isModel}
      >
        <LeftOutlined/>
      </PaginationButton>

      <PaginationButton
        className={`next-section-button ${isModel && '!rounded-none !rounded-r-2xl !mr-2'}`}
        disabled={currentPageIndex >= (numberOfPages - 1)}
        onClick={() => setCurrentPageIndex(inc)}
        isModel={isModel}
      >
        <RightOutlined/>
      </PaginationButton>

      <span className="section-number font-semibold">{!isModel && 'Section '}{currentPageIndex + 1}{isModel && `/${numberOfPages}`}</span>
    </div>
  );
};

const PreviewDocument = ({
  documentPreviewUrl,
  pagesHtml,
  previewDocumentHtml,
  previewHtmlLoading,
  classNameOrientationDocument,
  pageHeightDocument,
  activeKey,
  isModel,
  buttonToAdd
}) => {
  const getNumberOfPages = compose(
    length,
    defaultTo([])
  );

  const [numberOfPages, setNumberOfPages] = useState(getNumberOfPages(pagesHtml));
  const [currentPageIndex, setCurrentPageIndex] = useState(Number(activeKey));

  useEffect(() => {
    setNumberOfPages(getNumberOfPages(pagesHtml));
    setCurrentPageIndex(Number(activeKey));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagesHtml, activeKey]);

  const currentPageHtml = nth(currentPageIndex, defaultTo([], pagesHtml));

  return (
    <div className="h-full">
      <div className={classNames('bg-white py-4 pl-8 flex items-center', { 'shadow-none justify-end': isModel }, { 'place-content-between': !isModel })}>
        {!isModel && <span className="text-xl uppercase text-flexibranche-blue font-bold">Prévisualisation du document</span>}
        {buttonToAdd && buttonToAdd}
        <Pagination
          currentPageIndex={currentPageIndex}
          numberOfPages={numberOfPages}
          setCurrentPageIndex={setCurrentPageIndex}
          isModel={isModel}
        />
      </div>

      <div
        className={classNames('document-previewer', { 'h-[83vh]': isModel }, { 'overflow-x-auto bg-flexibranche-gray p-4': !isModel })}
        // eslint-disable-next-line no-restricted-syntax
        style={{ height: pageHeightDocument }}
      >
        {isNilOrEmpty(documentPreviewUrl) && isNilOrEmpty(currentPageHtml) && (
          <Button
            className="block mt-250px mx-auto mb-0 !h-45px text-base"
            onClick={previewDocumentHtml}
            loading={previewHtmlLoading}
            icon={<EyeOutlined className="text-[24px]" />}
          >
            Prévisualiser
          </Button>
        )}

        {!isNilOrEmpty(currentPageHtml) && (
          <div
            dangerouslySetInnerHTML={{ __html: currentPageHtml }}
            className={classNameOrientationDocument}
          />
        )}

        {!isNilOrEmpty(documentPreviewUrl) && (
          <DocumentWrapper documentUrl={documentPreviewUrl} setNumberOfPages={setNumberOfPages} numberOfPages={numberOfPages} />
        )}
      </div>
    </div>
  );
};

export default PreviewDocument;
