import { CheckOutlined, StopOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { isEmpty, prop, propOr } from 'ramda';
import React from 'react';
import useReview from '../hooks/useReview';
import ConfirmButton from './ConfirmButton';

const ReviewButtons = ({ item, reFetch }) => {
  const version = prop('version', item);
  const reviewComments = propOr({}, 'reviewComments', version);
  const {
    loading,
    rejectionReason,
    updateState,
    acceptReview,
    rejectReview,
    isReview,
    reviewIsRejected,
    isDraft
  } = useReview(version, reviewComments, item, reFetch);

  if (!isReview && (!reviewIsRejected || !isDraft)) {
    return null;
  }

  return (
    <>
      <ConfirmButton
        title={(
          <>
            <textarea
              placeholder="Expliquez pourquoi vous refusez la publication de cette offre"
              value={rejectionReason}
              onChange={e => updateState('rejectionReason', e.target.value)}
              className="w-400px h-100px border p-2"
            />
          </>
        )}
        onConfirm={rejectReview}
        icon={<StopOutlined />}
        buttonText="Refuser la publication"
        buttonType="primary"
        className="mr-2"
        sizeButton="large"
        loading={prop('reject', loading)}
        okButtonProps={{ disabled: isEmpty(rejectionReason) }}
        okText="OK"
      />

      <Button
        icon={<CheckOutlined />}
        loading={prop('accept', loading)}
        onClick={acceptReview}
        className="btn-green !h-45px"
        size="large"
      >
        Accepter la publication
      </Button>
    </>
  );
};

const WrapperReviewButtons = (props) => {
  const item = prop('item', props);
  if (!item) {
    return null;
  }
  return <ReviewButtons {...props}/>;
};

export default WrapperReviewButtons;
